import React from "react"

function CookiePolicyComponent() {
  const links = {
    gaoptout: "https://tools.google.com/dlpage/gaoptout",
    edge:
      "https://support.microsoft.com/de-at/help/4468242/microsoft-edge-browsing-data-and-privacy",
    chrome: "https://support.google.com/chrome/answer/95647?hl=de",
    firefox:
      "https://support.mozilla.org/de/kb/verbesserter-schutz-aktivitatenverfolgung-desktop",
    safari: "https://support.apple.com/de-at/guide/safari/sfri11471/mac",
    opera: "https://www.opera.com/help/tutorials/security/privacy/",
  }
  return (
    <div className="tnc-section" >
      <a className="anchor" id="cookie-policy"></a>
      <div className="container">
        <h3 className="bold title">Cookie-Richtlinie </h3>
        <div className="tnc-wrapper">
          <p>
            Sehr geehrter Besucher (im Folgenden "<strong>Sie</strong>" oder der
            "<strong>Benutzer"</strong>),
          </p>
          <p>
            Diese Website direkt-schutz.bolttechagency.at ("
            <strong>Website</strong>") ist Eigentum der Bolttech Digital
            Insurance Agency (EU) GmbH ("<strong>wir</strong>", "
            <strong>uns</strong>" oder die "<strong>Gesellschaft</strong>").
          </p>

          <p>
            Da unsere Website für verschiedene Zwecke "Cookies" verwendet,
            erklärt diese Cookie-Richtlinie, was die Cookies sind und wie sie
            auf der Website verwendet werden. Diese Cookie-Richtlinie, die auch
            in Abschnitt 14 der Verordnung EU 2016/679 (die "
            <strong>Verordnung</strong>") festgelegt ist, erklärt, wie wir
            solche Daten sammeln, für welche Zwecke und welche Rechte Sie haben.
          </p>

          <p>
            Einige Cookies (sogenannte First-Party-Cookies) werden direkt von
            uns gespeichert. Über solche Cookies erhebt und verarbeitet das
            Unternehmen einige Ihrer personenbezogenen Daten. Andere Cookies
            (sog. Third-Party-Cookies) werden von Dritten gespeichert.
          </p>

          <p>
            Bitte lesen Sie diese Cookie-Richtlinie und die allgemeine
            Datenschutzrichtlinie der Website sorgfältig durch, um eine
            fundierte Entscheidung zu treffen.
          </p>

          <h3 className="bold">
            A. Was sind Cookies und wofür werden sie verwendet.
          </h3>
          <p>
            Cookies sind kleine Textdateien, die die vom Nutzer besuchten
            Websites direkt an sein Endgerät (in der Regel an den Browser)
            senden. Solche Cookies werden auf dem Endgerät des Nutzers
            gespeichert, um bei einem erneuten Zugriff des Nutzers auf dieselben
            Websites erneut übertragen zu werden (sog. Erstanbieter-Cookies).
            Während des Surfens auf einer Website kann der Benutzer auch Cookies
            von verschiedenen Websites oder Webservern (sog.
            Third-Party-Cookies) auf seinem Endgerät empfangen; dies geschieht,
            weil die besuchten Websites Elemente wie Bilder, Karten, Töne,
            spezifische Links zu Seiten anderer Websites enthalten, die auf
            einem anderen Server als dem gehostet werden, der die angeforderte
            Seite hostet. Mit anderen Worten, es handelt sich um Cookies, die
            von einer anderen Website als derjenigen, die der Benutzer gerade
            besucht, gesetzt werden.
          </p>
          <p>
            Cookies können auf eine einzelne Browsersitzung beschränkt sein
            (sog. Session-Cookies) und werden automatisch deaktiviert, wenn der
            Benutzer den Browser schließt. Einige andere Cookies können ein
            voreingestelltes Ablaufdatum haben. In diesem Fall bleiben sie bis
            zu diesem Ablaufdatum auf der Festplatte des Benutzers gespeichert
            und aktiv, während sie bei nachfolgenden Browsersitzungen weiterhin
            Informationen sammeln (sog. permanente Cookies).
          </p>

          <p>
            Cookies werden für verschiedene Zwecke verwendet. Einige von ihnen
            werden verwendet, um dem Benutzer den Besuch einer Website und die
            Nutzung ihrer Funktionen zu ermöglichen (sogenannte technische
            Cookies). Einige andere Cookies werden verwendet, um statistische
            Informationen in aggregierter oder nicht aggregierter Form über die
            Anzahl der Nutzer, die auf eine Website zugreifen, und die Art und
            Weise, wie eine Website genutzt wird, zu sammeln (sog. Monitoring-
            oder Analyse-Cookies). Schließlich werden andere Cookies verwendet,
            um das Profil des Nutzers als Verbraucher zu verfolgen und um
            Werbung auf einer Website anzuzeigen, die Ihren Interessen
            entspricht (sog. Profil-Cookies).
          </p>

          <p>
            Die Website verwendet nur technische Cookies und Analyse-Cookies und
            verwendet keine Profilierungs-Cookies.
          </p>

          <p>
            Für weitere Details über diese Kategorien von Cookies lesen Sie
            bitte weiter in dieser Cookie-Richtlinie. Finden Sie heraus, wie sie
            funktionieren und wofür sie verwendet werden, und entscheiden Sie
            frei, ob Sie Ihre Zustimmung zu ihrer Verwendung geben oder nicht.
          </p>

          <h3 className="bold">B. Technische Cookies</h3>

          <p>
            Technische Cookies werden auf der Website ausschließlich zu dem
            Zweck verwendet, Ihnen den Besuch der Website und die Nutzung ihrer
            Funktionen zu ermöglichen. Diese Cookies sind immer
            Erstanbieter-Cookies, da sie direkt von uns über die Website
            gespeichert werden.
          </p>

          <p>
            Einige technische Cookies sind notwendig, um Ihnen ein optimiertes
            Nutzererlebnis zu bieten oder Ihre Authentifizierung auf der Website
            zu ermöglichen, z. B. um etwas auf der Website zu kaufen oder um auf
            Ihren persönlichen Bereich zuzugreifen (sogenannte
            Navigations-Cookies). In der Regel handelt es sich bei
            Navigations-Cookies um Session-Cookies, weshalb sie nach dem
            Schließen des Internetbrowsers automatisch deaktiviert werden.
          </p>

          <p>
            Andere technische Cookies sind nützlich, um dem Nutzer zu
            ermöglichen, einige seiner Präferenzen (z. B. Sprache oder
            Herkunftsland) zu speichern, ohne diese Präferenzen bei jedem Besuch
            der Website neu einstellen zu müssen (sog. Funktionalitäts-Cookies).
            Aus diesem Grund sind Funktionalitäts-Cookies oft persistente
            Cookies, da sie auch nach dem Schließen des Interessenbrowsers auf
            Ihrem Computer gespeichert bleiben, bis zum jeweiligen Verfallsdatum
            oder bis zum Löschen aus Ihrem Browser.
          </p>

          <p>
            Wie von den geltenden Datenschutzgesetzen gefordert, ist Ihre
            vorherige Zustimmung zur Erstellung dieser Cookies nicht
            erforderlich. Selbstverständlich steht es Ihnen frei, die
            Einrichtung von technischen Cookies zu blockieren, indem Sie die
            Einstellungen Ihres Internetbrowsers ändern (siehe Abschnitt D (1)).
            Bitte beachten Sie jedoch, dass die Blockierung der Einrichtung
            technischer Cookies oder das Löschen solcher Cookies die Möglichkeit
            beeinträchtigen kann, auf bestimmte Funktionen zuzugreifen, diese
            ganz oder teilweise zu nutzen, zu aktivieren/deaktivieren oder
            bestimmte Dienste zu erhalten.
          </p>
          <h3 className="bold">C. Cookies zur Überwachung oder "Analyse"</h3>
          <p>
            Analytics-Cookies werden auf der Website verwendet, um statistische
            Informationen über die Anzahl der Benutzer, die auf die Website
            zugreifen, und deren Besuch auf der Website zu sammeln, ob in
            aggregierter Form oder nicht.
          </p>
          <p>
            Bei den Analyse-Cookies dieser Website handelt es sich um Cookies
            von Drittanbietern, da sie nicht direkt vom Unternehmen, sondern von
            den entsprechenden Drittanbietern verwaltet werden.
          </p>

          <p>
            Analyse-Cookies von Drittanbietern werden nur mit Ihrer Zustimmung
            auf der Website installiert, da diese Cookies nicht anonymisiert
            sind und daher andere Dritte Zugriff auf disaggregierte Analysedaten
            auf IP-Adressenebene haben können (mit anderen Worten, diese Cookies
            können es Dritten theoretisch ermöglichen, Ihre Identität über die
            IP-Adresse zu verfolgen). Zu den auf dieser Website verwendeten
            Drittanbieter-Analyse-Cookies gehören:
          </p>
          <div className="tnc-table has-border">
            <table className="table-large">
              <tbody>
                <tr>
                  <td className="bold">Cookie Name</td>
                  <td className="bold">Cookie-Kategorie </td>
                  <td className="bold">Anweisungen für ihr Opt-out </td>
                </tr>
                <tr>
                  <td>Google Analytics (Google LLC) </td>
                  <td>Drittanbieter-Analyse-Cookie</td>
                  <td>
                    <a href={links.gaoptout} target="_blank">
                      {links.gaoptout}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table-mobile has-border">
              <tbody>
                <tr>
                  <td className="bold">Item</td>
                  <td className="bold">Details</td>
                </tr>
                <tr>
                  <td>Cookie Name</td>
                  <td>Google Analytics (Google LLC)</td>
                </tr>
                <tr>
                  <td>Cookie-Kategorie</td>
                  <td>Drittanbieter-Analyse-Cookie</td>
                </tr>
                <tr>
                  <td>Anweisungen für ihr Opt-out</td>
                  <td><a href={links.gaoptout} target="_blank">
                    {links.gaoptout}
                  </a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            Aus diesem Grund wird beim Zugriff auf die Website ein Banner
            eingeblendet, das Sie darüber informiert, dass (i) die Website
            Analyse-Cookies von Drittanbietern verwendet und (ii) Sie der
            Verwendung solcher Cookies zustimmen, indem Sie das Banner
            schließen, auf der Startseite scrollen oder auf ein anderes Element
            als das Banner klicken. Wenn Sie auf diese Weise Ihre Zustimmung zur
            Einrichtung von Cookies geben, protokollieren wir Ihre Zustimmung
            mittels eines technischen Cookies. Auf diese Weise verhindern wir,
            dass Sie das Banner bei Ihren zukünftigen Besuchen auf der Website
            erneut sehen. Bitte beachten Sie, dass, wenn Sie dieses technische
            Cookie durch den in Abschnitt D (1) beschriebenen Vorgang aus Ihrem
            Browser löschen, die Protokollierung Ihrer Einwilligung verloren
            geht, so dass bei Ihrem nächsten Besuch der Website das Banner auf
            den Cookies wieder angezeigt wird.
          </p>
          <p>
            Natürlich können Sie jederzeit frei entscheiden, die Speicherung von
            Analyse-Cookies zu blockieren, und in diesem Fall wird die
            Möglichkeit, die Seite zu besuchen oder von ihrem Inhalt zu
            profitieren, in keiner Weise beeinträchtigt. Wenn Sie wissen
            möchten, wie Sie dies tun können, lesen Sie bitte sorgfältig die
            Datenschutzrichtlinien der entsprechenden Drittanbieter, indem Sie
            auf die Links im Forum oben klicken.
          </p>

          <h3 className="bold">
            D. Wie Sie Cookies kontrollieren und deaktivieren können und wie Sie
            die Verwendung von Cookies ablehnen können
          </h3>

          <p>
            Es gibt mehrere Möglichkeiten, Cookies zu verwalten, zu deaktivieren
            oder zu löschen:
          </p>
          <h3 className="bold">
            (1) Ändern Sie die Einstellungen Ihres Browsers
          </h3>

          <p>
            Folgen Sie den Anweisungen des Entwicklers Ihres Internetbrowsers,
            um herauszufinden, wie Sie einige oder alle Cookies (technische und
            analytische Informationen) kontrollieren, deaktivieren oder löschen
            können:
          </p>

          <ul>
            <li>
              Edge: <a href={links.edge}>{links.edge}</a>
            </li>
            <li>
              Chrome: <a href={links.chrome}>{links.chrome}</a>
            </li>
            <li>
              Firefox: <a href={links.firefox}>{links.firefox}</a>
            </li>
            <li>
              Safari: <a href={links.safari}>{links.safari}</a>
            </li>
            <li>
              Opera: <a href={links.opera}>{links.opera}</a>
            </li>
          </ul>

          <p>
            Treffen Sie Ihre Entscheidungen sorgfältig. Wenn Sie alle Cookies,
            einschließlich der technischen, ablehnen, ohne eine Ausnahme auf der
            Website zu machen, wird dies Ihre Browsing-Aktivität
            beeinträchtigen, einschließlich der Nutzung bestimmter Funktionen.
            Darüber hinaus können durch das Blockieren aller Cookies auf dem
            Browser auch die technischen Cookies entfernt werden, so dass die
            von Ihnen auf der Website eingestellten Präferenzen entfernt werden
            können oder Sie nicht alle Funktionen der Website nutzen können.
          </p>
          <h3 className="bold">
            (2) Nutzung unserer interaktiven Tools oder von Tools Dritter
          </h3>
          <p>
            Um Cookies von Drittanbietern zu deaktivieren, lesen Sie bitte die
            Datenschutzrichtlinien der jeweiligen Drittanbieter, die
            Analyse-Cookies einrichten (siehe Abschnitt C oben), um
            herauszufinden, welche Tools zur Verfügung stehen, um Cookies zu
            handhaben, zu deaktivieren und zu löschen und ganz allgemein, um
            ihre Verwendung abzulehnen. Bitte beachten Sie, dass die
            Deaktivierung von Drittanbieter-Cookies (i) nicht nur Ihre Nutzung
            auf der Website, sondern auch auf allen anderen Websites, auf denen
            solche Cookies verwendet werden, verweigert und (ii) in keiner Weise
            Ihre Fähigkeit beeinträchtigt, auf die Website zuzugreifen und ihre
            Funktionen zu nutzen. Wenn Sie die Cookies von Drittanbietern
            deaktivieren, wird das Cookie-Banner weiterhin auf der Startseite
            angezeigt. Wenn Sie jedoch das Banner schließen oder auf der
            Homepage scrollen oder auf ein anderes Element als das Banner
            klicken, erhalten Sie keine Cookies von Dritten, die ordnungsgemäß
            entfernt worden sind.
          </p>
          <h3 className="bold">E. Für mehr Informationen</h3>

          <p>
            Wie in der Einleitung zu dieser Cookie-Richtlinie hervorgehoben,
            sammeln und verarbeiten wir bestimmte Ihrer persönlichen Daten durch
            die Cookies, die über die Website verwaltet werden
            (Erstanbieter-Cookies und Drittanbieter-Analyse-Cookies).
          </p>

          <p>
            Wir verarbeiten die Daten über die technischen Cookies, um Ihr und
            unser berechtigtes Interesse daran zu verfolgen, dass die Website
            korrekt funktioniert und Sie ihre Funktionalitäten sicher nutzen
            können. Wir verarbeiten die Daten über die Analyse-Cookies, um unser
            berechtigtes Interesse an statistischen Gesamtdaten darüber zu
            verfolgen, wie unsere Nutzer mit der Website interagieren.
          </p>
          <p>
            Eine solche Verarbeitung Ihrer Daten nur auf elektronischem Wege,
            vollständig automatisiert und ohne menschliches Zutun. Daher werden
            unsere Mitarbeiter niemals auf den Inhalt Ihrer persönlichen Daten
            zugreifen, die durch Cookies erhalten wurden.
          </p>

          <p>
            Personenbezogene Daten, die durch Cookies erhalten werden, können
            auf Servern gespeichert werden, die von unseren Lieferanten von
            IT-Dienstleistungen verwaltet werden, oder es kann von diesen
            Lieferanten, die als Datenverarbeiter in unserem Namen handeln,
            darauf zugegriffen werden.
          </p>

          <p>
            Weitere Informationen über Ihre Datenschutzrechte finden Sie in der
            unten stehenden Datenschutzerklärung.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CookiePolicyComponent
