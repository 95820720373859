import React, { Component } from "react"

class PrivacyPolicyComponent extends Component {
  render() {
    const links = {
      dsb: "http://www.dsb.gv.at/",
      privacy_mail: "privacy@bolttech.eu."
    }
    return (
      <div className="tnc-section">
        <a className="anchor" id="privacy-policy"></a>
        <div className="container">
          <div className="bold title">
            <h2>DATENSCHUTZRICHTLINIE</h2>
          </div>
          <div className="tnc-wrapper">
            <h5 className="bold">
              Allgemeine Informationen über persönliche Daten
            </h5>
            <p>
              Diese Datenschutzerklärung wird von Bolttech Digital Insurance
              Agency Brokerage (EU) GmbH, ("Bolttech"), einem
              Versicherungsmakler der AIG Europe S.A., mit Sitz am Kohlmarkt
              8-10, 1010 Wien, und der Firmennummer 531434x, und registriert in
              Wien unter der GISA-Nummer 32620474, bereitgestellt. Sie betrifft
              die Verarbeitung personenbezogener Daten, die von Bolttech im
              Zusammenhang mit der Erbringung von Versicherungsdienstleistungen
              für Sie in Verbindung mit mobilen Geräten, die Sie von Hutchison
              Drei Austria GmbH ("
              <strong>Drei</strong>") erworben haben, erhoben und verarbeitet
              werden.
            </p>
            <p>
              Der Schutz Ihrer persönlichen Daten ist uns sehr wichtig und wir
              möchten mit Ihnen offen darüber sprechen.
            </p>
            <p>
              Wir halten uns strikt an die europäischen Vorgaben der
              Europäischen Datenschutzgrundverordnung (EU) 2016/679 ("
              <strong>GDPR</strong>") und die österreichischen nationalen
              Datenschutzbestimmungen, wie insbesondere die DSGVO, das
              österreichische Datenschutzgesetz (DSG) und das TKG 2003. Um Ihre
              Daten vor dem Zugriff und Missbrauch durch unberechtigte Personen
              zu schützen, setzen wir stets angemessene technische und
              organisatorische Sicherheitsmaßnahmen ein, insbesondere durch
              spezielle logische und physische Zugriffsregelungen.
            </p>
            <p>
              In unserer Datenschutzerklärung erfahren Sie, welche Daten wir
              über Sie verarbeiten und wie Sie jederzeit über den Umgang mit
              Ihren persönlichen Daten entscheiden können.
            </p>
            <h5 className="bold">
              Welche personenbezogenen Daten von Ihnen werden von uns erhoben
              und verarbeitet?
            </h5>
            Wenn Sie Ihren Versicherungsschutz bei uns abschließen, werden wir
            die folgenden Daten über Sie erheben und verarbeiten:
            <table className="tnc-table no-border">
              <tbody>
                <tr>
                  <td>
                    <strong>Angaben zur Person:</strong>
                  </td>
                  <td>
                    Titel, Name, vollständige Adresse, E-Mail-Adresse und
                    Telefonnummer, Angaben zu Ihren mobilen Geräten und deren
                    Marke, Modell, Preis und IMEI-Nummer.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Details zur Identifizierung:</strong>
                  </td>
                  <td>
                    Identifikationsnummern, die von Ämtern oder Behörden
                    vergeben werden, z. B. Sozialversicherungsnummer,
                    Passnummer, Personalausweisnummer, Steuernummer,
                    Führerscheinnummer.
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Daten im Zusammenhang mit der Verwaltung Ihres
                      Versicherungsschutzes:
                    </strong>
                  </td>
                  <td>
                    Informationen und Details, die Sie über Ihre
                    Versicherungsansprüche angeben, einschließlich aller
                    Berichte oder Informationen, die zur Unterstützung Ihres
                    Anspruchs angefordert wurden.
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h5 className="bold">
              Zweck und Rechtsgrundlage für die Verarbeitung
            </h5>
            <p>
              Mit Ausnahme von Marketingdaten, Profiling und besonderen
              Datenkategorien werden Ihre Daten von uns ohne Ihre ausdrückliche
              Zustimmung verarbeitet, um die Versicherungsleistungen zu
              erbringen. Insbesondere werden die Daten verarbeitet:
            </p>
            <div className="alpha-list">
              <ol>
                <li>
                  zur Durchführung der von Ihnen gewünschten
                  Versicherungsleistungen (einschließlich des Ausfüllens von
                  Anträgen)
                  </li>
                <li>
                  zur Beantwortung von Kundenanfragen, -beschwerden und
                  -mitteilungen sowie zur Bearbeitung von Schadensfällen;
                  </li>
                <li>
                  um Kundenfeedback zu den angebotenen Dienstleistungen zu
                  sammeln und zu verwalten;
                  </li>

                <li>
                  um uns zu helfen, Betrug, Geldwäsche, Terrorismus und andere
                  Verbrechen zu verhindern, indem wir überprüfen, was wir über
                  Sie wissen.
                  </li>
                <li>
                  zur allgemeinen Erfüllung gesetzlicher, buchhalterischer und
                  steuerlicher Verpflichtungen.
                  </li>
              </ol>
            </div>
            <h5 className="bold">Übertragung der Daten</h5>
            <p>
              Die von Ihnen zur Verfügung gestellten Daten werden nicht an
              Dritte weitergegeben, mit Ausnahme der unabhängigen
              Datenverwalter, die zu den folgenden Kategorien gehören:
              </p>
            <ul>
              <li>
                Ihre Versicherungsgesellschaft, AIG Europe S.A., Direktion für
                Österreich ("Versicherer"), zum Zwecke der Erbringung Ihrer
                Versicherungsleistungen;
                </li>

              <li>
                Öffentliche Einrichtungen oder Behörden, wenn dies gesetzlich
                vorgeschrieben ist oder um die Begehung von Straftaten oder
                Verbrechen zu verhindern oder zu unterdrücken; und
                </li>
              <li>
                Hutchison Drei Austria GmbH ("Drei") zum Zwecke des
                Verständnisses Ihrer Präferenzen.
                </li>
            </ul>
            <p>
              Die von Ihnen zur Verfügung gestellten Daten können auch an die
              folgenden Personen weitergegeben werden, die aufgrund besonderer
              vertraglicher Vereinbarungen als Datenverarbeiter fungieren:
            </p>
            <ul>
              <li>
                Der Verwalter Ihrer Versicherungsleistungen im Rahmen Ihres
                Versicherungsschutzes, Bolttech Device Protection (AUT) GmbH
                ("Verwalter"), der diese Tätigkeiten im Auftrag Ihres
                Versicherers durchführt;
                </li>
              <li>
                Andere Unternehmen, mit denen der Administrator Vereinbarungen
                und/oder Kooperationen zur Verwaltung der Leistungen Ihrer
                Versicherung, einschließlich Reparaturen und Ersatz Ihrer
                versicherten mobilen Geräte, abgeschlossen hat oder
                abschließen wird
                </li>

              <li>
                Bolttech kann Ihre personenbezogenen Daten auch an andere
                Unternehmen der Bolttech-Gruppe weitergeben, die
                Datenverarbeitungsdienste für Konzernunternehmen erbringen.
                </li>
            </ul>
            <h5>Verarbeitung innerhalb/außerhalb des EWR</h5>
            <p>
              Im Rahmen der Verfolgung der oben genannten Zwecke kann Bolttech
              Ihre personenbezogenen Daten in andere Länder übermitteln. Wenn
              Bolttech Daten überträgt oder an andere außerhalb des Europäischen
              Wirtschaftsraums weitergibt, stellen wir sicher, dass diese
              Personen oder Unternehmen, die Ihre personenbezogenen Daten
              erhalten, sich verpflichten, diese vor unzulässiger Verwendung
              oder Offenlegung gemäß den Datenschutzgesetzen zu schützen und die
              entsprechenden Schutzmaßnahmen gemäß der GDPR zu ergreifen.
            </p>
            <h5>Aufbewahrungsfrist</h5>
            <p>
              Unsere Richtlinien zur Datenspeicherung entsprechen allen
              geltenden Gesetzen und Datenschutzbestimmungen, denen wir
              unterliegen. Sie legen die Zeiträume fest, in denen wir alle
              verschiedenen Arten von Daten, die wir besitzen, aufbewahren
              dürfen, und werden regelmäßig überprüft.
            </p>
            <p>
              Wir vernichten die Daten sicher und in Übereinstimmung mit den in
              unseren Richtlinien festgelegten Fristen.
            </p>
            <p>
              Wenn wir Daten zu Statistik- und Forschungszwecken
              weiterverwenden, stellen wir sicher, dass die Daten anonymisiert
              werden, so dass Sie ohnehin nicht damit oder daraus identifiziert
              werden können.
            </p>
            <h5>Sie haben diese Rechte.</h5>
            <p>
              Sie haben das Recht, jederzeit unentgeltlich Auskunft über die zu
              Ihrer Person gespeicherten Daten, deren Herkunft und Empfänger
              sowie den Zweck der Datenverarbeitung zu erhalten. Außerdem haben
              Sie das Recht auf Datenübertragbarkeit, Einschränkung,
              Berichtigung, Widerspruch und nach Ablauf gesetzlicher Fristen auf
              Löschung Ihrer Daten. Bitte kontaktieren Sie uns per E-Mail an
            <a href={"mailto:" + links.privacy_mail}>{links.privacy_mail}</a>
            </p>
            <p>
              Sollte es einmal Anlass zu Beschwerden über die Verarbeitung Ihrer
              Daten geben, können Sie sich auch jederzeit an die Österreichische
              Datenschutzbehörde wenden. Weitere Informationen finden Sie unter
              <a href={links.dsb} target="_blank">
                {links.dsb}.
              </a>
            </p>
            <h5>Hohe Sicherheitsstandards für Daten </h5>
            <p>
              Wir schützen die auf unseren Servern gespeicherten Daten nach dem
              aktuellen Stand der Technik. Sollte es trotz der hohen
              Sicherheitsanforderungen zu einem Datenverlust oder einer
              Verletzung des Datenschutzes kommen, werden besondere Maßnahmen
              ergriffen: Technische und/oder manuelle Schwachstellen werden
              sofort erkannt und die Fehler so schnell wie möglich behoben. Je
              nach Schweregrad werden die betroffenen Personen und die
              Datenschutz- oder Aufsichtsbehörde informiert. Gegebenenfalls
              setzen wir Verschlüsselung oder andere Sicherheitsmaßnahmen ein,
              die wir für angemessen halten, um Ihre persönlichen Daten zu
              schützen. Wir überprüfen unsere Sicherheitsverfahren auch
              regelmäßig, um geeignete neue Technologien und aktualisierte
              Methoden zu berücksichtigen, aber trotz unserer angemessenen
              Bemühungen ist keine Sicherheitsmaßnahme jemals perfekt oder
              undurchdringlich.
            </p>
            <br />
            <strong> Zuletzt aktualisiert: 23.12.2020 </strong>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPolicyComponent
