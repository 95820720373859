import React, { useEffect } from 'react';
import PrivacyPolicyComponent from '../components/privacy-policy/privacy-policy.component';
import Layout from '../components/shared/layout';
import CookiePolicyComponent from '../components/terms-and-conditions/cookie-policy.component';
import { WebsiteTermsofUseComponent } from '../components/terms-and-conditions/website-terms-of-use.component';



const TosPage = () => {

  useEffect(() => {
    const queryParam = (window.location.search).substring(1);
    if (queryParam === 'datenschutzrichtlinie')
      scrollTopEl(document.getElementById('privacy-policy'))
  }, [])

  const scrollTopEl = (el) => {
    const positionTop = el.getBoundingClientRect().top;
    window.scrollTo({ top: window.scrollY + positionTop });
  }

  return (
    <div className="tnc-page">
      <Layout header={true} isBackBtn={true} footer={true}>
        <WebsiteTermsofUseComponent />
        <CookiePolicyComponent />
        <PrivacyPolicyComponent id="privacy-policy" />
      </Layout>
    </div>
  )
}

export default TosPage;